.project-state,
.project-state-1 {
    width: 100%;
    align-items: flex-start;
    background-color: var(--white);
    border-radius: 10px;
    display: flex;
    justify-content: flex-end;
    padding: 17px 18px;
    position: relative;
}

.contract-signed,
.contract-signed-1 {
    letter-spacing: -0.44px;
    min-width: 174px;
    white-space: nowrap;
    display: flex;
    align-items: center;
}

.contract-signed-mobile .line-8 {
    height: 1px;
    object-fit: cover;
    left: 20px;
    top: 70px;
    width: calc(100% - 40px);
    position: absolute;
    font-size: 19px;
    visibility: hidden;
}

.contract-signed-mobile {
    font-size: var(--font-size-xxl);
}

.overlap-group1 {
    position: absolute;
    left: 20px;
    top: 95px;
    align-items: flex-start;
    background-color: var(--botticelli);
    border-radius: 16px;
    display: flex;
    gap: 17px;
    height: 144px;
    justify-content: flex-end;
    min-width: 244px;
    padding: 28px 9px;
}

.text-27 {
    align-self: center;
    letter-spacing: 0;
    line-height: 79px;
    margin-top: 3px;
    min-height: 81px;
    min-width: 90px;
    text-align: center;
    white-space: nowrap;
}

.thank-you-for-signin {
    letter-spacing: -0.32px;
    line-height: 22px;
    min-height: 86px;
}

.your-project-is-ready-to-schedule {
    letter-spacing: -0.4px;
    line-height: 20px;
    min-height: 24px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.were-hard-at-work-s {
    letter-spacing: -0.32px;
    line-height: 22px;
    width: 100%;
}

.flex-col-2 {
    position: absolute;
    left: 285px;
    top: 95px;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 7px;
    margin-top: 3px;
    min-height: 73px;
}

@media (max-width: 768px) {
    .project-state-message {
        hyphens: auto;
    }
}
