div.icon {
    display: flex;
    border-radius: 15px;
    justify-content: center;
    align-items: center;
    padding: 3px 6px;
}

div.icon-filled {
    border-radius: 0;
    position: absolute;
}
