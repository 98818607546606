.foreman-label {
    letter-spacing: -0.28px;
    line-height: 14px;
    margin-top: 15px;
    min-height: 17px;
    width: 139px;
}

.foreman-name {
    letter-spacing: -0.32px;
    line-height: 18px;
    margin-top: 3px;
    min-height: 20px;
    white-space: wrap;
}

.foreman-photo-div {
    position: relative;
    overflow: hidden;
    width: 80px;
    height: 80px;
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.foreman-img-width {
    width: 100%;
}

.foreman-img-height {
    height: 100%;
    text-align: center;
}
