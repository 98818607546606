div.project-documents {
    height: calc(100% - 313px);
    position: relative;
}

.project-documents-title {
    letter-spacing: -0.36px;
    line-height: 24px;
    white-space: nowrap;
    margin-bottom: 25px;
    display: flex;
    align-items: baseline;
}

.project-documents-title-text {
    position: absolute;
    left: 24.5px;
    top: 4px;
}

.project-documents-items {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}

.project-documents-items-mobile {
    position: static;
    height: auto;
}

.project-documents-item {
    display: flex;
    align-items: flex-start;
    gap: 6px;
    margin-bottom: 25px;
}

.group-64.group-64-2 {
    z-index: 5;
}

.icon-play {
    height: 11px;
    margin-top: 4px;
    object-fit: cover;
    width: 10px;
}

.overlap-group3 {
    height: 40px;
    margin-top: -1px;
    position: relative;
    width: 303px;
}

.crawlspace-mold-reme {
    letter-spacing: -0.32px;
    line-height: 20px;
}

.icon-pdf {
    display: inline;
    letter-spacing: 0;
    color: var(--cloud-burst);
    font-family: var(--font-family-fontawesome_6_pro-solid);
    font-size: 26px;
    font-style: normal;
}

.project-documents-link {
    text-decoration: none;
    color: #066888;
}

.project-documents-link:hover {
    text-decoration: underline;
    color: #066888;
}

.main-content-11 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-left: 457px;
    margin-top: 50px;
    min-height: 1241px;
    position: relative;
    width: 975px;
    z-index: 3;
}

.horizontal-divder-long-11 {
    height: 2px;
    margin-top: 13px;
    object-fit: cover;
    width: 973px;
}

.group-156-6 {
    align-items: flex-end;
    background-color: var(--white);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    min-height: 512px;
    padding: 20px 19px;
    position: relative;
    width: 973px;
}

.flex-row-33 {
    align-items: center;
    align-self: center;
    display: flex;
    margin-left: 1px;
    min-width: 932px;
}

.check-completed-41 {
    align-items: flex-end;
    background-color: var(--cloud-burst);
    border-radius: 15px;
    display: flex;
    height: 30px;
    justify-content: flex-end;
    min-width: 30px;
    padding: 1.5px 3px;
}

.text-516 {
    letter-spacing: 0;
    line-height: 20px;
    min-height: 23px;
    min-width: 22px;
    text-align: center;
    white-space: nowrap;
}

.project-complete-7 {
    align-self: flex-end;
    letter-spacing: -0.44px;
    line-height: 22px;
    margin-left: 12px;
    min-height: 26px;
    min-width: 187px;
    white-space: nowrap;
}

.group-63-6 {
    align-items: flex-end;
    display: flex;
    height: 22px;
    justify-content: space-between;
    margin-left: 596px;
    margin-top: 2px;
    width: 107px;
}

.hide-details-11 {
    height: 17px;
    letter-spacing: -0.28px;
    line-height: 14px;
    margin-bottom: 0.5px;
    text-align: right;
    white-space: nowrap;
    width: 82px;
}

.image-11 {
    object-fit: cover;
    width: 18px;
}

.line-8-11 {
    height: 1px;
    margin-right: 0.5px;
    margin-top: 22px;
    object-fit: cover;
    width: 932px;
}

.group-container-3 {
    align-items: flex-end;
    display: flex;
    gap: 25px;
    height: 293px;
    margin-top: 25px;
    min-width: 933px;
}

.overlap-group2-6 {
    align-items: flex-start;
    background-color: var(--gallery);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    min-height: 293px;
    padding: 24px 25px;
    position: relative;
    width: 544px;
}

.it-looks-like-you-ha-1 {
    cursor: pointer;
    letter-spacing: -0.4px;
    line-height: 20px;
    min-height: 24px;
    white-space: nowrap;
}

.our-service-program-3 {
    letter-spacing: -0.32px;
    line-height: 22px;
    margin-top: 7px;
    min-height: 108px;
    width: 490px;
}

.group-73-3 {
    align-items: center;
    background-color: var(--white);
    border: 2px solid;
    border-color: var(--glacier);
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    min-height: 292px;
    padding: 20px 0;
    width: 364px;
}

.fall-is-on-the-way {
    letter-spacing: -0.36px;
    line-height: 22px;
    min-height: 44px;
    width: 322px;
}

.although-the-gutter {
    letter-spacing: -0.32px;
    line-height: 22px;
    margin-right: 4px;
    margin-top: 9px;
    min-height: 130px;
    width: 318px;
}

.progress-step-1-8 {
    align-items: flex-start;
    align-self: flex-start;
    background-color: var(--sprout);
    border-radius: 10px;
    display: flex;
    height: 41px;
    margin-left: 21px;
    margin-top: 21px;
    min-width: 188px;
    padding: 10px 24.5px;
}

.call-15 {
    display: flex;
    height: 21px;
    justify-content: space-between;
    width: 135px;
}

.text-522 {
    letter-spacing: 0;
    line-height: 20px;
    margin-bottom: -2.5px;
    margin-top: 0.5px;
    text-align: right;
    white-space: nowrap;
    width: 22px;
}

.phone-15 {
    letter-spacing: -0.32px;
    line-height: 16px;
    margin-right: -2px;
    margin-top: 1px;
    white-space: nowrap;
    width: 105px;
}

.rectangle-9-11 {
    background-color: var(--gallery);
    height: 1080px;
    left: 1488px;
    position: fixed;
    top: 0;
    width: 432px;
    z-index: 1;
}
