div.error-page {
    padding: 55px 48px;
    background-color: var(--black-haze);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

@media (max-width: 768px) {
    div.error-page {
        padding: 0;
    }
}

div.error-page-modal-background {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
}

div.error-page-modal {
    border: 2px solid var(--orange);
    border-radius: 14px;
    padding: 61px 48px 53px;
    background-color: var(--white);
}

@media (max-width: 768px) {
    div.error-page-modal {
        padding: 33px 15px 40px 16px;
    }
}

div.error-page-modal-heading {
    display: flex;
    align-items: center;
    gap: 12px;
}

@media (max-width: 768px) {
    div.error-page-modal-heading {
        flex-direction: column;
        gap: 8px;
        font-size: 32px;
    }
}

div.error-page-modal-message {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    letter-spacing: 0;
    line-height: 37px;
}

@media (max-width: 768px) {
    div.error-page-modal-message {
        margin-top: 8px;
        font-size: 27px;
        letter-spacing: -0.1px;
        line-height: 28px;
    }
}

div.error-page-modal-instructions {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    letter-spacing: -0.36px;
    line-height: 24px;
    text-align: center;
}

@media (max-width: 768px) {
    div.error-page-modal-instructions {
        margin-top: 12px;
    }
}

div.error-page-buttons {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    gap: 20px;
}

@media (max-width: 768px) {
    div.error-page-buttons {
        margin-top: 20px;
        flex-direction: column;
        align-items: center;
        gap: 12px;
    }
}
