div.project-state-content {
    padding-bottom: 30px;
}

@media (max-width: 768px) {
    div.project-state-content {
        padding-bottom: 20px;
    }
}

.row-survey {
    margin: 0;
    display: flex;
    gap: 30px;
}

.col-survey {
    padding: 0 0 0 0;
    min-width: 270px;
}

.col-promo {
    padding: 0;
    min-width: 270px;
}
