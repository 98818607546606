.offer-card {
    margin-bottom: 20px;
    border-radius: 10px;
    padding: 24px 30px 26px 30px;
    border: none;
    background-color: white;
    cursor: pointer;
}

.offer-card:hover {
    border: solid #72b1c8 2px;
    background-color: #f4fafc;
}

@media (max-width: 768px) {
    .offer-card {
        padding: 17px 13px 14px 18px;
    }
}

.offer-card-selected {
    border: solid #72b1c8 2px;
    background-color: #f4fafc;
    cursor: default;
}
