div.navigation {
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--white);
    padding: 10px 35.3px 30px 35.3px;
}

div.navigation-mobile {
    width: 500px;
}

.navigation-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
}
