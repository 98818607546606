div.project-property {
    width: 100%;
    padding: 50px 65px 0 65px;
    display: "flex";
}

@media (max-width: 768px) {
    div.project-property {
        padding: 20px 20px 0 20px;
    }
}

hr.project-property {
    height: 2px;
    background-color: black;
    opacity: 1;
    border: none;
    margin-bottom: 35px;
}

@media (max-width: 768px) {
    hr.project-property {
        visibility: hidden;
        margin: 0 0 15px 0;
    }
}

div.project-progress {
    margin: 0 65px 31px 65px;
}

div.project-progress-mobile {
    margin: 0 10px 20px 10px;
}

.project-1 {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0 65px 50px 65px;
    overflow-y: auto;
}

.project-1-mobile {
    gap: 10px;
    padding: 0 10px 30px 10px;
}

.project-2 {
    align-items: flex-end;
    display: flex;
    gap: 12px;
    height: 72px;
    min-width: 178px;
}

.text-container,
.text-container-1 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 28px;
    margin-bottom: -2.5px;
    min-height: 74px;
    width: 22px;
}

.text-4,
.text-9 {
    letter-spacing: 0;
    line-height: 20px;
    min-height: 23px;
    white-space: nowrap;
    cursor: pointer;
}

.text-4:hover {
    color: #066888;
}

.text-4:hover .text-5 {
    color: #066888;
}

.text-5 {
    margin-left: 16px;
}

#spanLogOut {
    margin-left: 14px;
}

.flex-col,
.flex-col-1 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 27px;
    margin-bottom: -1px;
    min-height: 73px;
    width: 144px;
}

.flex-col-item,
.flex-col-item-1 {
    letter-spacing: -0.4px;
    line-height: 20px;
    min-height: 23px;
    white-space: nowrap;
    cursor: pointer;
}

.project-property-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-wrap: wrap;
    gap: 5px;
}

.project-property-header-left,
.project-property-header-right {
    flex: 1;
    min-width: fit-content;
}

.project-property-header-right {
    text-align: right;
    margin-left: auto;
}
