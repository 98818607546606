div.project-state-new {
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 10px;
    padding: 0 18px;
}

div.project-state-new-grayed {
    background-color: #eceff0;
}

div.project-state-header {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px 0 17px 0;
}

@media (max-width: 768px) {
    div.project-state-header {
        padding: 7px 0 7px 0;
    }
}

img.project-state-content-separator {
    height: 1px;
    width: 100%;
}

div.project-state-content-container {
    width: 100%;
    padding: 0 0 0 0;
    margin: 0 0 0 0;
    min-width: 100%;
}

div.project-state-stamp {
    background-color: var(--botticelli);
    border-radius: 16px;
    display: flex;
    gap: 17px;
    justify-content: flex-start;
    width: 244px;
    padding: 28px 9px;
}

div.project-state-stamp-mobile {
    background-color: var(--botticelli);
    border-radius: 16px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 18px;
    padding: 14px 14px 14px 14px;
}

div.project-state-stamp-mobile-text {
    letter-spacing: -0.32px;
    line-height: 22px;
}

div.project-state-stamp-complete {
    background-color: var(--gallery);
    gap: 7px;
    padding: 24px 16px;
}

.group-container-1 {
    align-items: flex-start;
    display: flex;
    gap: 25px;
    height: 265px;
    margin-top: 25px;
    min-width: 933px;
}

img.path-61 {
    height: 8px;
    object-fit: cover;
    width: 7px;
}

p.project-state-stamp-congratulations {
    cursor: pointer;
    letter-spacing: -0.4px;
    line-height: 20px;
    margin-right: 1px;
    min-height: 46px;
    margin-bottom: 5px;
}

p.project-state-stamp-our-service-program {
    letter-spacing: -0.32px;
    line-height: 22px;
    margin-bottom: 5px;
}
