.user-options-1 {
    display: flex;
    align-items: flex-start;
    background-color: var(--gallery);
    border-radius: 14px;
    height: 136px;
    min-width: 294px;
    padding: 31px;
}

.user-options-2 {
    align-items: flex-end;
    display: flex;
    gap: 12px;
    height: 72px;
    min-width: 178px;
}

.user-options-text-selected {
    color: #066888;
}

.user-options-text-selected .text-5 {
    color: #066888;
}

.user-options-text:hover {
    color: #066888;
}

.user-options-text:hover .text-5 {
    color: #066888;
}

.text-5 {
    margin-left: 16px;
}

#spanLogOut {
    margin-left: 14px;
}

.flex-col,
.flex-col-1 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 27px;
    margin-bottom: -1px;
    min-height: 73px;
    width: 144px;
}

.flex-col-item,
.flex-col-item-1 {
    letter-spacing: -0.4px;
    line-height: 20px;
    min-height: 23px;
    white-space: nowrap;
    cursor: pointer;
}
