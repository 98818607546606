.daily-work-row {
    margin: 0;
    padding: 0;
    margin-top: auto;
}

.daily-work-row-mobile {
    margin: 0;
    padding: 0;
    margin-top: 20px;
}

div.daily-work-group {
    position: relative;
    padding: 15px 20px 56px 20px;
    margin: 0 0 15px 0;
    min-width: 305px;
    max-width: 800px;
    flex-grow: 1;
    flex-basis: 0;
}

div.daily-work-group-no-button {
    padding-bottom: 0;
}

div.daily-work-group-mobile {
    margin: 0 0 15px 0;
}

div.daily-work-button {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 41px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--sprout);
    color: var(--cloud-burst);
    border-radius: 0 0 12px 12px;
    cursor: pointer;
}

div.daily-work-button:hover {
    background-color: var(--feijoa);
}
