div.content-separator {
    background-color: #dfdfdf;
    overflow: hidden;
}

div.content-separator-horizontal {
    height: 2px;
    margin: 11px 0 0 0;
}

div.content-separator-vertical {
    width: 2px;
    margin: 0 40px 0 40px;
    height: 100%;
}

img.content-separator {
    height: 2px;
    width: 100%;
}
