div.content-frame {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 50px 0 20px;
    display: flex;
    flex-direction: column;
}

@media (max-width: 768px) {
    div.content-frame {
        padding: 20px 0 0;
    }
}

div.content-frame-header {
    display: flex;
    flex-direction: column;
    padding: 0 58px;
}

@media (max-width: 768px) {
    div.content-frame-header {
        padding: 0 12px;
    }
}

@media (max-width: 768px) {
    div.content-frame-header-title {
        font-size: 26px;
    }
}

div.content-frame-header-logout {
    display: flex;
    gap: 8px;
    align-items: center;
    margin-top: 4px;
}

div.content-frame-header-separator {
    margin-top: 1px;
    margin-bottom: 20px;
}

@media (max-width: 768px) {
    div.content-frame-header-separator {
        margin-bottom: 0;
    }
}

div.content-frame-address {
    display: none;
}

@media (max-width: 768px) {
    div.content-frame-address {
        margin-top: -1px;
        display: block;
    }
}

div.content-frame-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    flex: 1;
    padding: 0 58px;
}

@media (max-width: 768px) {
    div.content-frame-body {
        padding: 0 12px;
    }
}
