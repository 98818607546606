div.navigation-logo {
    margin-bottom: 20px;
    color: gray;
}

div.navigation-logo-loading {
    margin-bottom: 34px;
    width: 80%;
    max-width: 443px;
}

div.navigation-logo-mobile {
    margin-top: 15px;
    margin-bottom: 0;
}
@media (min-width: 768px) {
    div.navigation-logo-mobile {
        margin-left: 20px;
    }
}
@media (max-width: 768px) {
    div.navigation-logo-mobile {
        margin-left: 20px;
    }
}

div.navigation-logo-mobile-offers {
    margin-left: 12px;
}

img.navigation-logo {
    width: 309px;
}

img.navigation-logo-height {
    /* to go by height */
    width: auto;
    height: 108px;
}

img.navigation-logo-mobile {
    width: 215px;
    height: auto;
}

div.navigation-logo-mobile-height {
    /* to go by height */
    height: 85px;
}

img.navigation-logo-mobile-height {
    /* to go by height */
    width: auto;
    height: 85px;
}

img.navigation-logo-loading {
    height: auto;
    width: 100%;
}
