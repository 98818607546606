div.notification {
    border: 2px solid #f9c20a;
    border-radius: 10px;
    padding: 8px 16px;
    display: flex;
    justify-content: space-between;
    gap: 12px;
    align-items: center;
    position: relative;
}

div.notification-icon-filled {
    padding: 0 16px 0 0;
}

div.notification-content {
    display: flex;
    gap: 12px;
    align-items: center;
}

@media (max-width: 768px) {
    div.notification-project-state-message {
        margin-top: 10px;
        margin-bottom: 15px;
    }
}

@media (min-width: 768px) {
    div.notification-on-the-way {
        margin-bottom: 20px;
    }
}
