div.property {
    width: 432px;
    height: 660px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: var(--gallery);
}

div.property-mobile {
    width: 500px;
    height: auto;
}

@media (max-width: 768px) {
    div.property-bottom {
        padding-bottom: 50px;
    }
}
