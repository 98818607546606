div.survey {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

div.survey-header {
    display: flex;
    gap: 10px;
}

div.survey-title {
    line-height: 22px;
    letter-spacing: -0.4px;
}

div.survey-body {
    line-height: 22px;
    letter-spacing: -0.32px;
    margin-top: 10px;
    max-width: var(--text-max-width);
}

div.survey-links {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 10px;
}

img.survey-review-icon {
    width: 31px;
}

div.survey-button {
    margin-top: 20px;
}
