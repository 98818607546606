div.spoc-button {
    position: absolute;
    right: 10px;
    bottom: 94px;
    width: 176px;
    max-width: 400px;
    height: 57px;
    background-color: var(--white);
    border: 2px solid var(--glacier);
    border-radius: 14px;
    display: flex;
    cursor: pointer;
    transition: width 0.3s, height 0.3s;
    overflow: hidden;
}

div.spoc-button-expanded {
    width: calc(100% - 20px);
    max-width: 400px;
    height: 110px;
}

div.spoc-button-contents {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 6px 9px 6px 9px;
    gap: 10px;
}

div.spoc-button-image {
    width: 45px;
    height: 45px;
    background: var(--botticelli);
    border-radius: 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
