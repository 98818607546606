.cp-modal-dialog {
    max-width: 710px;
    margin: 20px auto 20px auto;
}

.cp-modal-dialog-mobile {
    margin: 16px 12px 20px 12px;
}

.cp-modal-content {
    border-radius: 14px;
    border: none;
    background-color: var(--white);
}

.modal-backdrop {
    opacity: 0.6 !important;
    overflow: hidden;
}

.modal-body {
    padding: 24px 31px 45px 32px;
    display: flex;
    flex-direction: column;
}

.modal-body-mobile {
    padding: 19px 11px 32px 15px;
}

.modal-title {
    display: flex;
    gap: 20px;
    justify-content: space-between;
}

.modal-title-text {
    letter-spacing: -0.48px;
    line-height: normal;
}

.modal-title-close {
    cursor: pointer;
    line-height: normal;
}

.modal-body-content {
    margin-top: 30px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    gap: 17px;
}

.modal-body-content-mobile {
    margin-top: 20px;
    margin-bottom: 30px;
    gap: 11px;
}

.daily-work-modal-footer {
    display: flex;
    justify-content: flex-end;
}

.daily-work-modal-button {
    letter-spacing: -0.36px;
    line-height: 19px;
    background-color: var(--sprout);
    border: none;
    padding: 11px 36px;
    color: var(--cloud-burst);
    border-radius: 10px;
}

.daily-work-modal-button:hover {
    background-color: var(--feijoa);
    color: var(--cloud-burst);
}
