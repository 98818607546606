div.account-details {
    width: 100%;
    margin-top: 6px;
}

div.account-details-header {
    letter-spacing: -0.52px;
    line-height: 28px;
}

div.account-details-contact-phone {
    letter-spacing: -0.36px;
    line-height: 24px;
    margin: 20px 0 0 0;
}

div.account-details-contact-phone-mobile {
    margin: 10px 0 0 0;
}

div.account-details-contact-phone-number {
    display: inline;
    white-space: nowrap;
}

.account-details-user-row {
    margin: 49px 0 0 0;
    padding: 30px 0;
    border: 2px solid #72b1c8;
    border-radius: 14px;
    background-color: var(--white);
}

.account-details-user-row-mobile {
    margin: 30px 0 20px 0;
    padding: 21px 0 6px 0;
}

.account-details-user-col {
    padding: 0 40px;
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding-bottom: 28px;
}

@media (max-width: 768px) {
    .account-details-user-col {
        padding: 0 17px;
        gap: 15px;
        padding-bottom: 15px;
    }
}

.account-details-user-col-left {
    border-right: solid #dfdfdf 1px;
}

.account-details-user-col-right {
    border-left: solid #dfdfdf 1px;
}

div.account-details-user-details {
    display: flex;
    flex-direction: column;
    gap: 25px;
    height: 100%;
    width: calc(50% - 41px);
}

.account-details-user-property {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    gap: 10px;
    margin: 0;
}

.account-details-user-property-mobile {
    flex-direction: column;
    gap: 8px;
}

div.account-details-user-label {
    letter-spacing: -0.4px;
    line-height: 22px;
    min-width: 100px;
}

div.account-details-user-value {
    letter-spacing: -0.4px;
    line-height: 21px;
    overflow: hidden;
    text-overflow: ellipsis;
}
