.view-contract {
    position: relative;
    margin-top: 10px;
    background-color: var(--sprout);
    border-radius: 10px;
    display: flex;
    height: 41px;
    justify-content: space-between;
    width: 244px;
    cursor: pointer;
}

.view-contract:hover {
    background-color: var(--feijoa);
}

.view-signed-contract {
    align-self: flex-end;
    height: 20px;
    letter-spacing: -0.32px;
    line-height: 16px;
    margin-bottom: -8.6px;
    margin-right: 21px;
    white-space: nowrap;
    width: 170px;
}

.view-contract.view-contract-1,
.view-contract.view-contract-2 {
    margin-left: 4px;
    margin-top: 12px;
}
