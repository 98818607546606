.check-active,
.check-active-1,
.check-active-2 {
    display: flex;
    align-items: center;
    background-color: var(--cloud-burst);
    border-radius: 10.5px;
    height: 21px;
    width: 21px;
    margin-left: 24px;
}

.file-pdf,
.file-pdf-1,
.file-pdf-2 {
    margin-left: 5px;
    white-space: nowrap;
}
