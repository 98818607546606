.main-button {
    background-color: var(--sprout);
    border-radius: 10px;
    display: flex inline;
    height: 41px;
    align-items: center;
}

.main-button:hover {
    background-color: var(--feijoa);
}

div.main-button-sign-out {
    border-style: solid;
    border-width: 2px;
    border-color: var(--sprout);
    background-color: var(--white);
}

div.main-button-sign-out:hover {
    border-color: var(--feijoa);
    background-color: var(--feijoa);
}
