div.special-offers-body {
    width: 100%;
}

@media (max-width: 768px) {
    div.special-offers-body {
        top: 105px;
        padding: 12px 0;
    }
}

@media (max-width: 768px) {
    div.special-offers-body {
        position: static;
        height: auto;
        overflow-y: visible;
    }
}
