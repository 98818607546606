div.daily-work-content {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

div.daily-work-content-popup {
    gap: 12px;
}

div.daily-work-content-heading {
    display: flex;
    gap: 13px;
    align-items: center;
    letter-spacing: -0.4px;
    line-height: 22px;
}

div.daily-work-content-heading-popup {
    letter-spacing: -0.36px;
    line-height: normal;
}

div.daily-work-content-description {
    letter-spacing: -0.36px;
    line-height: 24px;
}

div.daily-work-content-note {
    background: #f9c20a 0% 0% no-repeat padding-box;
    border-radius: 4px;
    padding: 4px 9.7px 4px 10.3px;
    font-style: italic;
    letter-spacing: -0.32px;
    line-height: 22px;
}

div.daily-work-content-date {
    display: flex;
    justify-content: right;
}

div.daily-work-content-note-prefix {
    display: inline;
    font-style: italic;
}

div.daily-work-content-details {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-left: 20.1px;
}

div.daily-work-content-details-popup {
    margin-left: 0;
    gap: 10px;
}

div.daily-work-content-detail {
    letter-spacing: -0.32px;
    line-height: 22px;
    display: flex;
    gap: 8px;
}

img.daily-work-content-bullet {
    width: 8px;
    height: 9px;
    margin-top: 6px;
}
