.menu-item {
    display: flex;
    height: 32px;
    justify-content: space-between;
    margin-left: 2px;
    width: 278px;
    cursor: pointer;
}

.menu-item-mobile {
    height: auto;
    width: 180px;
    flex-direction: column;
    margin-left: 0;
    align-items: center;
    justify-content: center;
    padding-top: 5px;
}

.menu-item-icon {
    height: 34px;
    letter-spacing: 0;
    line-height: 32px;
    text-align: center;
    white-space: nowrap;
    width: 26px;
}

.menu-item-icon-mobile {
    width: auto;
}

.menu-item-label {
    align-self: flex-end;
    height: 28px;
    letter-spacing: 0;
    line-height: 24px;
    margin-right: -2px;
    white-space: nowrap;
    width: 227px;
}

.menu-item-label-mobile {
    align-self: auto;
    margin-right: 0;
    width: auto;
}
