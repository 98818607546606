.workflow {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.workflow-card {
    width: 100%;
    border-radius: 14px;
    border: 2px solid #72b1c8;
}
