div.property-address {
    display: flex;
    flex-direction: column;
}

@media (max-width: 768px) {
    div.property-address {
        gap: 8px;
    }
}

@media (max-width: 768px) {
    div.property-address-single-project {
        flex-direction: row;
        gap: 4px;
        height: 40px;
        align-items: center;
    }
}

div.property-address-multi-project {
    padding: 13px 0;
}

div.property-address-header {
    color: #63666a;
    margin-bottom: 12px;
}

@media (max-width: 768px) {
    div.property-address-header {
        margin-bottom: 8px;
    }
}

@media (max-width: 768px) {
    div.property-address-header {
        margin-bottom: 0;
    }
}

div.property-address-content {
    margin-bottom: 20px;
}

@media (max-width: 768px) {
    div.property-address-content {
        margin-bottom: 0;
    }
}

hr.property-address {
    height: 2px;
    background-color: #dfdfdf;
    opacity: 1;
    border: none;
    margin: 0;
    margin-bottom: 25px;
}

@media (max-width: 768px) {
    hr.property-address {
        margin-bottom: 8px;
    }
}

hr.property-address-multi-project {
    margin-bottom: 8px;
}
