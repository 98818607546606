div.user-load-error-dialog {
    max-width: 650px;
}

div.user-load-error {
    border: 2px solid var(--orange);
    display: flex;
    flex-direction: column;
}

div.user-load-error-body {
    padding: 28px 27px 26px 27px;
}

div.user-load-error-title {
    display: flex;
    gap: 9px;
    letter-spacing: -0.48px;
    line-height: 26px;
}

div.user-load-error-icon {
    width: 52px;
    height: 52px;
    border-radius: 26px;
    letter-spacing: -0.4px;
    line-height: 26px;
}

div.user-load-error-message {
    margin-top: 21px;
}

div.user-load-error-buttons {
    margin-top: 30px;
    display: flex;
    justify-content: right;
    gap: 20px;
}

@media (max-width: 768px) {
    div.user-load-error-buttons {
        flex-direction: column;
        align-items: center;
    }
}
