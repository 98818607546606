div.date-stamp {
    background: var(--botticelli) 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    padding: 8px 7px 11px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

div.date-stamp-date {
    letter-spacing: -0.28px;
    line-height: 15px;
}

div.date-stamp-label {
    letter-spacing: -0.32px;
    line-height: 18px;
}
