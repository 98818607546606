.cp-link {
    color: var(--allports) !important;
    text-decoration: underline;
}

.cp-link-inline-text {
    font-size: inherit !important;
    text-decoration: none;
}

.cp-link:hover {
    color: var(--allports);
    text-decoration: underline;
}
