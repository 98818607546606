div.forman-row {
    margin: 0;
    display: flex;
    gap: 10px;
    justify-content: space-evenly;
}

div.forman-row-col-date {
    padding: 0;
    max-width: "115px";
}
