:root {
    --abbey: #505257;
    --allports: #087aa1;
    --black: #000000;
    --black-haze: #f6f6f6;
    --botticelli: #c9e4eeab;
    --cloud-burst: #172f53;
    --error: #cc0000;
    --feijoa: #a1d884;
    --gallery: #eeeeee;
    --glacier: #72b1c8;
    --sprout: #bde3a8;
    --white: #ffffff;
    --grayed: #b9b9b9;
    --orange: #f9c20a;

    --font-size-l: 18px;
    --font-size-m: 16px;
    --font-size-s: 14px;
    --font-size-xs: 12px;
    --font-size-xl: 20px;
    --font-size-xxl: 22px;
    --font-size-xxxl: 24px;
    --font-size-xxxxl: 33px;
    --font-size-xxxxxl: 40px;
    --font-size-xxxxxxl: 54px;

    --font-family-fontawesome_6_pro-light: "FontAwesome 6 Pro-Light", Helvetica;
    --font-family-fontawesome_6_pro-solid: "FontAwesome 6 Pro-Solid", Helvetica;
    --font-family-fontawesome_6_pro-thin: "FontAwesome 6 Pro-Thin", Helvetica;
    --font-family-fontawesome_6_pro_solid-solid: "FontAwesome 6 Pro Solid-Solid",
        Helvetica;
    --font-family-gotham: "Gotham", Helvetica;
    --font-family-gotham-bold: "Gotham-Bold", Helvetica;
    --font-family-gotham-book: "Gotham-Book", Helvetica;
    --font-family-gotham-bookitalic: "Gotham-BookItalic", Helvetica;
    --font-family-gotham-medium: "Gotham-Medium", Helvetica;

    --text-max-width: 735px;
}

@media (min-width: 769px) {
    .mobile-only {
        display: none;
        visibility: hidden;
    }
}

.cp-text {
    max-width: var(--text-max-width);
}

div.cp-error {
    color: var(--error);
    padding: 5px 0;
}

.gotham-book-normal-black-16px {
    color: var(--black);
    font-family: var(--font-family-gotham-book);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 400;
}

.gotham-book-italic-black-16px {
    color: var(--black);
    font-family: var(--font-family-gotham-book);
    font-size: var(--font-size-m);
    font-style: italic;
    font-weight: 400;
}

.gotham-book-normal-black-18px {
    color: var(--black);
    font-family: var(--font-family-gotham-book);
    font-size: var(--font-size-l);
    font-style: normal;
    font-weight: 400;
}

.fontawesome6pro-solid-normal-black-20px {
    color: var(--black);
    font-family: var(--font-family-fontawesome_6_pro-solid);
    font-size: var(--font-size-xl);
    font-style: normal;
    font-weight: 400;
}

.fontawesome6pro-solid-normal-black-24px {
    color: var(--black);
    font-family: var(--font-family-fontawesome_6_pro-solid);
    font-size: var(--font-size-xxxl);
    font-style: normal;
    font-weight: 400;
}

.fontawesome6pro-solid-normal-black-38px {
    color: var(--black);
    font-family: var(--font-family-fontawesome_6_pro-solid);
    font-size: 38px;
    font-style: normal;
    font-weight: 400;
}

.fontawesome6pro-solid-normal-yellow-40px {
    color: var(--orange);
    font-family: var(--font-family-fontawesome_6_pro-solid);
    font-size: var(--font-size-xxxxxl);
    font-style: normal;
    font-weight: 400;
}

.fontawesome6pro-solid-normal-feijoa-14px {
    color: var(--feijoa);
    font-family: var(--font-family-fontawesome_6_pro-solid);
    font-size: var(--font-size-s);
    font-style: normal;
    font-weight: 400;
}

.fontawesome6pro-solid-normal-white-16px {
    color: var(--white);
    font-family: var(--font-family-fontawesome_6_pro-solid);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 400;
}

.fontawesome6pro-solid-normal-grayed-16px {
    color: var(--grayed);
    font-family: var(--font-family-fontawesome_6_pro-solid);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 400;
}

.gotham-book-normal-black-20px {
    color: var(--black);
    font-family: var(--font-family-gotham-book);
    font-size: var(--font-size-xl);
    font-style: normal;
    font-weight: 400;
}

.gotham-book-normal-black-22px {
    color: var(--black);
    font-family: var(--font-family-gotham-book);
    font-size: var(--font-size-xxl);
    font-style: normal;
    font-weight: 400;
}

.fontawesome6pro-light-cloud-burst-79px {
    color: var(--cloud-burst);
    font-family: var(--font-family-fontawesome_6_pro-light);
    font-size: 79px;
    font-style: normal;
    font-weight: 300;
}

.fontawesome6pro-light-cloud-burst-50px {
    color: var(--cloud-burst);
    font-family: var(--font-family-fontawesome_6_pro-light);
    font-size: 50px;
    font-style: normal;
    font-weight: 300;
}

.fontawesome6pro-light-allports-18px {
    color: var(--allports);
    font-family: var(--font-family-fontawesome_6_pro-light);
    font-size: var(--font-size-l);
    font-style: normal;
    font-weight: 300;
}

.gotham-bold-black-12px {
    color: var(--black);
    font-family: var(--font-family-gotham-bold);
    font-size: var(--font-size-xs);
    font-style: normal;
    font-weight: 700;
}

.gotham-bold-black-16px {
    color: var(--black);
    font-family: var(--font-family-gotham-bold);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 700;
}

.gotham-bold-black-italic-16px {
    color: var(--black);
    font-family: var(--font-family-gotham-bold);
    font-size: var(--font-size-m);
    font-style: italic;
    font-weight: 700;
}

.gotham-book-normal-allports-14px {
    color: var(--allports);
    font-family: var(--font-family-gotham-book);
    font-size: var(--font-size-s);
    font-style: normal;
    font-weight: 400;
}

.fontawesome6prosolid-solid-normal-allports-22px {
    color: var(--allports);
    font-family: var(--font-family-fontawesome_6_pro_solid-solid);
    font-size: var(--font-size-xxl);
    font-style: normal;
    font-weight: 400;
}

.gotham-bold-black-20px {
    color: var(--black);
    font-family: var(--font-family-gotham-bold);
    font-size: var(--font-size-xl);
    font-style: normal;
    font-weight: 700;
}

.gotham-bold-black-22px {
    color: var(--black);
    font-family: var(--font-family-gotham-bold);
    font-size: var(--font-size-xxl);
    font-style: normal;
    font-weight: 700;
}

.gotham-bold-black-24px {
    color: var(--black);
    font-family: var(--font-family-gotham-bold);
    font-size: var(--font-size-xxxl);
    font-style: normal;
    font-weight: 700;
}

.gotham-bold-black-26px {
    color: var(--black);
    font-family: var(--font-family-gotham-bold);
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
}

.gotham-bold-allports-20px {
    color: var(--allports);
    font-family: var(--font-family-gotham-bold);
    font-size: var(--font-size-xl);
    font-style: normal;
    font-weight: 700;
}

.gotham-bold-allports-18px {
    color: var(--allports);
    font-family: var(--font-family-gotham-bold);
    font-size: var(--font-size-l);
    font-style: normal;
    font-weight: 700;
}

.gotham-bold-allports-22px {
    color: var(--allports);
    font-family: var(--font-family-gotham-bold);
    font-size: var(--font-size-xxl);
    font-style: normal;
    font-weight: 700;
}

.fontawesome6pro-thin-black-14px {
    color: var(--black);
    font-family: var(--font-family-fontawesome_6_pro-thin);
    font-size: var(--font-size-s);
    font-style: normal;
    font-weight: 100;
}

.fontawesome6prosolid-solid-normal-abbey-18px {
    color: var(--abbey);
    font-family: var(--font-family-fontawesome_6_pro_solid-solid);
    font-size: var(--font-size-xl);
    font-style: normal;
    font-weight: 400;
}

.fontawesome6prosolid-solid-normal-abbey-20px {
    color: var(--abbey);
    font-family: var(--font-family-fontawesome_6_pro_solid-solid);
    font-size: var(--font-size-xl);
    font-style: normal;
    font-weight: 400;
}

.gotham-book-normal-abbey-20px {
    color: var(--abbey);
    font-family: var(--font-family-gotham-book);
    font-size: var(--font-size-xl);
    font-style: normal;
    font-weight: 400;
}

.gotham-book-normal-allports-16px {
    color: var(--allports);
    font-family: var(--font-family-gotham-book);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 400;
}

.gotham-normal-black-16px {
    color: var(--black);
    font-family: var(--font-family-gotham);
    font-size: var(--font-size-m);
}

.gotham-book-normal-cloud-burst-16px {
    color: var(--cloud-burst);
    font-family: var(--font-family-gotham-book);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 400;
}

.gotham-book-normal-cloud-burst-18px {
    color: var(--cloud-burst);
    font-family: var(--font-family-gotham-book);
    font-size: var(--font-size-l);
    font-style: normal;
    font-weight: 400;
}

.gotham-book-normal-cloud-burst-20px {
    color: var(--cloud-burst);
    font-family: var(--font-family-gotham-book);
    font-size: var(--font-size-xl);
    font-style: normal;
    font-weight: 400;
}

.gotham-book-normal-abbey-14px {
    color: var(--abbey);
    font-family: var(--font-family-gotham-book);
    font-size: var(--font-size-s);
    font-style: normal;
    font-weight: 400;
}

.fontawesome6pro-solid-normal-allports-24px {
    color: var(--allports);
    font-family: var(--font-family-fontawesome_6_pro-solid);
    font-size: var(--font-size-xxxl);
    font-style: normal;
    font-weight: 400;
}

.fontawesome6pro-solid-normal-cloud-burst-20px {
    color: var(--cloud-burst);
    font-family: var(--font-family-fontawesome_6_pro-solid);
    font-size: var(--font-size-xl);
    font-style: normal;
    font-weight: 400;
}

.fontawesome6pro-solid-normal-cloud-burst-24px {
    color: var(--cloud-burst);
    font-family: var(--font-family-fontawesome_6_pro-solid);
    font-size: var(--font-size-xxxl);
    font-style: normal;
    font-weight: 400;
}

.fontawesome6pro-solid-normal-cloud-burst-26px {
    color: var(--cloud-burst);
    font-family: var(--font-family-fontawesome_6_pro-solid);
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
}

.fontawesome6pro-solid-normal-cloud-burst-33px {
    color: var(--cloud-burst);
    font-family: var(--font-family-fontawesome_6_pro-solid);
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
}

.fontawesome6pro-solid-normal-glacier-32px {
    color: var(--glacier);
    font-family: var(--font-family-fontawesome_6_pro-solid);
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
}

.fontawesome6pro-solid-normal-glacier-28px {
    color: var(--glacier);
    font-family: var(--font-family-fontawesome_6_pro-solid);
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
}

.gotham-bold-abbey-24px {
    color: var(--abbey);
    font-family: var(--font-family-gotham-bold);
    font-size: var(--font-size-xxxl);
    font-style: normal;
    font-weight: 700;
}

.gotham-bold-abbey-14px {
    color: var(--abbey);
    font-family: var(--font-family-gotham-bold);
    font-size: var(--font-size-s);
    font-style: normal;
    font-weight: 700;
}

.gotham-italic-abbey-14px {
    color: var(--abbey);
    font-family: var(--font-family-gotham-bookitalic);
    font-size: var(--font-size-s);
    font-style: normal;
    font-weight: 400;
}

.fontawesome6pro-solid-normal-abbey-32px {
    color: var(--abbey);
    font-family: var(--font-family-fontawesome_6_pro-solid);
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
}

.fontawesome6pro-solid-normal-abbey-28px {
    color: var(--abbey);
    font-family: var(--font-family-fontawesome_6_pro-solid);
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
}

.fontawesome6pro-solid-normal-abbey-24px {
    color: var(--abbey);
    font-family: var(--font-family-fontawesome_6_pro-solid);
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
}

.gotham-book-normal-abbey-24px {
    color: var(--abbey);
    font-family: var(--font-family-gotham-book);
    font-size: var(--font-size-xxxl);
    font-style: normal;
    font-weight: 400;
}

.gotham-book-normal-abbey-12px {
    color: var(--abbey);
    font-family: var(--font-family-gotham-book);
    font-size: var(--font-size-xs);
    font-style: normal;
    font-weight: 400;
}

.gotham-bold-cloud-burst-33px {
    color: var(--cloud-burst);
    font-family: var(--font-family-gotham-bold);
    font-size: var(--font-size-xxxxl);
    font-style: normal;
    font-weight: 700;
}

.gotham-bold-cloud-burst-54px {
    color: var(--cloud-burst);
    font-family: var(--font-family-gotham-bold);
    font-size: var(--font-size-xxxxxxl);
    font-style: normal;
    font-weight: 700;
}

.gotham-bold-cloud-burst-26px {
    color: var(--cloud-burst);
    font-family: var(--font-family-gotham-bold);
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
}

.gotham-bold-cloud-burst-24px {
    color: var(--cloud-burst);
    font-family: var(--font-family-gotham-bold);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
}

.gotham-medium-black-18px {
    color: var(--black);
    font-family: var(--font-family-gotham-medium);
    font-size: var(--font-size-l);
    font-style: normal;
    font-weight: 500;
}

.gotham-medium-black-20px {
    color: var(--black);
    font-family: var(--font-family-gotham-medium);
    font-size: var(--font-size-xl);
    font-style: normal;
    font-weight: 500;
}

.fontawesome6pro-solid-normal-white-20px {
    color: var(--white);
    font-family: var(--font-family-fontawesome_6_pro-solid);
    font-size: var(--font-size-xl);
    font-style: normal;
    font-weight: 400;
}

.gotham-book-normal-cloud-burst-22px {
    color: var(--cloud-burst);
    font-family: var(--font-family-gotham-book);
    font-size: var(--font-size-xxl);
    font-style: normal;
    font-weight: 400;
}

.gotham-book-normal-cloud-burst-14px {
    color: var(--cloud-burst);
    font-family: var(--font-family-gotham-book);
    font-size: var(--font-size-s);
    font-style: normal;
    font-weight: 400;
}

.gotham-normal-allports-16px {
    color: var(--allports);
    font-family: var(--font-family-gotham);
    font-size: var(--font-size-m);
}

.gotham-bold-black-18px {
    color: var(--black);
    font-family: var(--font-family-gotham-bold);
    font-size: var(--font-size-l);
    font-style: normal;
    font-weight: 700;
}

.gotham-medium-cloud-burst-18px {
    color: var(--cloud-burst);
    font-family: var(--font-family-gotham-medium);
    font-size: var(--font-size-l);
    font-style: normal;
    font-weight: 500;
}

.gotham-medium-allports-20px {
    color: var(--allports);
    font-family: var(--font-family-gotham-medium);
    font-size: var(--font-size-xl);
    font-style: normal;
    font-weight: 500;
}

.gotham-bold-cloud-burst-19px {
    color: var(--cloud-burst);
    font-family: var(--font-family-gotham-bold);
    font-size: var(--font-size-xl);
    font-style: normal;
    font-weight: 700;
}

.gotham-bold-cloud-burst-20px {
    color: var(--cloud-burst);
    font-family: var(--font-family-gotham-bold);
    font-size: var(--font-size-xl);
    font-style: normal;
    font-weight: 700;
}

.gotham-book-normal-abbey-16px {
    color: var(--abbey);
    font-family: var(--font-family-gotham-book);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 400;
}

.gotham-book-normal-abbey-18px {
    color: var(--abbey);
    font-family: var(--font-family-gotham-book);
    font-size: var(--font-size-l);
    font-style: normal;
    font-weight: 400;
}

.gotham-book-normal-allports-18px {
    color: var(--allports);
    font-family: var(--font-family-gotham-book);
    font-size: var(--font-size-l);
    font-style: normal;
    font-weight: 400;
}
