div.note {
    display: flex;
    letter-spacing: -0.32px;
    line-height: 22px;
    gap: 2px 0;
}

div.note-text {
    white-space: nowrap;
}
