:root {
    --service-plan-horiz-margin: 30px;
}

div.service-plan {
    background-color: var(--gallery);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 25px var(--service-plan-horiz-margin);
    max-width: calc(
        var(--text-max-width) + var(--service-plan-horiz-margin) * 2
    );
}

@media (max-width: 360px) {
    div.service-plan {
        margin-right: 20px;
    }
}

div.service-plan-title {
    line-height: 22px;
    letter-spacing: -0.4px;
    margin-bottom: 12px;
}

div.service-plan-body {
    line-height: 22px;
    letter-spacing: -0.32px;
    margin-bottom: 5px;
}

p.service-date {
    margin-top: 6px;
}
