.project-state-details {
    display: flex;
    height: 22px;
    justify-content: space-between;
    align-items: center;
    margin-left: 5px;
    cursor: pointer;
}

.see-details {
    height: 17px;
    letter-spacing: -0.28px;
    line-height: 14px;
    margin-top: 2.5px;
    margin-right: 5px;
    text-align: right;
    white-space: nowrap;
}

.text-2-1 {
    letter-spacing: 0;
    line-height: 22px;
    margin-bottom: -2px;
    margin-right: -2px;
    white-space: nowrap;
    width: 19px;
}

.project-state-details.group-62,
.project-state-details.group-62-2 {
    align-self: center;
    margin-bottom: unset;
    margin-left: 580px;
    margin-top: 3px;
}

.project-state-details.group-62-1,
.project-state-details.group-62-3 {
    align-self: center;
    margin-bottom: unset;
    margin-left: 585px;
    margin-top: 3px;
}

.image {
    object-fit: cover;
    width: 18px;
}
