.menu {
    display: flex;
}

.menu-desktop {
    flex-direction: column;
    margin-top: 20px;
    gap: 40px;
}

.menu-mobile {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 80px;
    flex-direction: row;
    justify-content: center;
    box-shadow: 0px -3px 8px #0000000f;
    background-color: var(--white);
}

.text-28 {
    height: 34px;
    letter-spacing: 0;
    line-height: 32px;
    text-align: center;
    white-space: nowrap;
    width: 26px;
}

.your-project {
    align-self: flex-end;
    height: 28px;
    letter-spacing: 0;
    line-height: 24px;
    margin-right: -2px;
    white-space: nowrap;
    width: 227px;
}

.text-29 {
    height: 34px;
    letter-spacing: 0;
    line-height: 32px;
    text-align: center;
    white-space: nowrap;
    width: 34px;
}
