.cp-modal-dialog {
    max-width: 710px;
    margin: 20px auto 20px auto;
}

.cp-modal-dialog-mobile {
    margin: 16px 12px 20px 12px;
}

.cp-modal-content {
    border-radius: 14px;
    border: none;
    background-color: var(--white);
}

.modal-backdrop {
    opacity: 0.6 !important;
    overflow: hidden;
}

.modal-body {
    padding: 24px 31px 45px 32px;
    display: flex;
    flex-direction: column;
}

.modal-body-mobile {
    padding: 19px 11px 32px 15px;
}

.modal-title {
    display: flex;
    gap: 20px;
    justify-content: space-between;
}

.modal-title-text {
    letter-spacing: -0.48px;
    line-height: normal;
}

.modal-title-close {
    cursor: pointer;
    line-height: normal;
}

.multi-project-popup-content {
    margin-top: 20px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.multi-project-popup-project-group {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.multi-project-popup-project-group-name {
    letter-spacing: -0.4px;
    line-height: 22px;
}

.multi-project-popup-projects {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.multi-project-popup-project {
    border: 2px solid #72b1c8;
    border-radius: 10px;
    padding: 16px 22px 12px 23px;
    cursor: pointer;
}

.multi-project-popup-project-selected {
    background: #f4fafc 0% 0% no-repeat padding-box;
}

.multi-project-popup-project-cancelled {
    background: #eceff0 0% 0% no-repeat padding-box;
    cursor: default;
    border-color: #63666a;
}

.multi-project-popup-project:hover {
    background: #f4fafc 0% 0% no-repeat padding-box;
}

.multi-project-popup-project-cancelled:hover {
    background: #eceff0 0% 0% no-repeat padding-box;
}

.multi-project-popup-address {
    letter-spacing: -0.4px;
    line-height: 22px;
}

.multi-project-popup-address-selected {
    text-decoration: underline;
}

.multi-project-popup-project-type {
    letter-spacing: -0.32px;
    line-height: 18px;
    margin-top: 6px;
}

.multi-project-popup-contract-signed {
    letter-spacing: -0.28px;
    line-height: 16px;
    margin-top: 8px;
    text-align: right;
}

.daily-work-modal-footer {
    display: flex;
    justify-content: flex-end;
}

.daily-work-modal-button {
    letter-spacing: -0.36px;
    line-height: 19px;
    background-color: var(--sprout);
    border: none;
    padding: 11px 36px;
    color: var(--cloud-burst);
    border-radius: 10px;
}

.daily-work-modal-button:hover {
    background-color: var(--feijoa);
    color: var(--cloud-burst);
}
