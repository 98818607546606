div.progress {
    position: relative;
}

div.progress-static {
    width: 100%;
    max-width: none;
    height: 30px;
}

div.progress-loading {
    width: 80%;
    max-width: 443px;
    height: 10px;
}

div.progress-gradient {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 14px;
}

div.progress-block {
    position: absolute;
    background-color: var(--black-haze);
    height: 100%;
}

div.progress-cover {
    position: absolute;
    background-color: var(--white);
    height: 100%;
    border: 1px solid #b9b9b9;
}

div.progress-cover-static {
    border-radius: 14px 14px 14px 14px;
}

div.progress-cover-progress {
    border-radius: 0 14px 14px 0;
}

div.progress-cover-loading {
    border-radius: 14px 14px 14px 14px;
}
