@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
/* The following line is used to measure usage of this code. You can remove it if you want. */
/* @import url("https://px.animaapp.com/647a298e80ae17166b58e624.647a298f80ae17166b58e627.SYtIFIP.rcp.png"); */

@font-face {
    font-family: "Gotham-Medium";
    font-style: normal;
    font-weight: 500;
    src: url("static/fonts/Gotham Medium.otf") format("opentype");
}
@font-face {
    font-family: "Gotham-Book";
    font-style: normal;
    font-weight: 400;
    src: url("static/fonts/Gotham-Book.otf") format("opentype");
}
@font-face {
    font-family: "Gotham-Light";
    font-style: normal;
    font-weight: 300;
    src: url("static/fonts/Gotham Thin.otf") format("opentype");
}
@font-face {
    font-family: "FontAwesome 6 Pro-Solid";
    font-style: normal;
    font-weight: 400;
    src: url("static/fonts/Font Awesome 6 Pro-Solid-900.otf") format("opentype");
}
@font-face {
    font-family: "FontAwesome 6 Pro-Light";
    font-style: normal;
    font-weight: 300;
    src: url("static/fonts/Font Awesome 6 Pro-Light-300.otf") format("opentype");
}
@font-face {
    font-family: "Gotham-Bold";
    font-style: normal;
    font-weight: 700;
    src: url("static/fonts/Gotham-Bold.otf") format("opentype");
}
@font-face {
    font-family: "FontAwesome 6 Pro-Thin";
    font-style: normal;
    font-weight: 100;
    src: url("static/fonts/Font Awesome 6 Pro-Thin-100.otf") format("opentype");
}
@font-face {
    font-family: "FontAwesome 6 Pro Solid-Solid";
    font-style: normal;
    font-weight: 400;
    src: url("static/fonts/Font Awesome 6 Pro-Solid-900.otf") format("opentype");
}
@font-face {
    font-family: "Gotham-BookItalic";
    font-style: italic;
    font-weight: 400;
    src: url("static/fonts/Gotham-BookItalic.otf") format("opentype");
}

.screen a {
    display: contents;
    text-decoration: none;
}

.container-center-horizontal {
    display: flex;
    flex-direction: row;
    justify-content: center;
    pointer-events: none;
    width: 100%;
}

.container-center-horizontal > * {
    flex-shrink: 0;
    pointer-events: auto;
}

* {
    box-sizing: border-box;
}
