div.main {
    max-width: 1800px;
    padding: 0;
}

.main-container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 0;
}

.main-container-row {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0;
}

@media (max-width: 768px) {
    .main-container-row {
        position: absolute;
        height: calc(100% - 80px);
        overflow-y: auto;
    }
}

.navigation-column {
    display: flex;
    overflow-y: hidden;
    flex-direction: column;
    align-items: center;
    background-color: var(--white);
    gap: "20px";
    justify-content: space-between;
    padding: 40px 20px 20px 20px;
}

.navigation-column-desktop {
    max-width: 450px;
    min-width: 350px;
}

.content-column {
    display: flex;
    flex-direction: column;
    align-items: left;
    background-color: var(--black-haze);
    padding: 0;
}

.content-column-desktop {
    min-width: 510px;
    height: 100%;
}

.property-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: var(--gallery);
    padding: 40px 20px 20px 20px;
    min-width: 250px;
}

.property-column-desktop {
    max-width: 400px;
    min-width: 400px;
}
