.project-state-completed {
    border-radius: 15px;
    display: flex;
    height: 30px;
    width: 30px;
    min-width: 30px;
    justify-content: center;
    align-items: center;
}

.project-state-completed-mobile {
    border-radius: 12px;
    height: 24px;
    width: 24px;
    min-width: 24px;
}

.workflow-checkbox {
    justify-content: center;
    align-items: center;
    height: 23px;
    width: 23px;
    min-width: 23px;
    padding: 3px 6px;
}

.dailyworktask-checkbox {
    justify-content: center;
    align-items: center;
    height: 18px;
    width: 18px;
    min-width: 18px;
    padding: 3px 6px;
}

div.project-state-check {
    font-size: 20px;
}

@media (max-width: 768px) {
    div.project-state-check {
        font-size: 16px;
    }
}

div.project-state-check-workflow {
    font-size: 16px;
}

div.project-state-check-dailyworktask {
    font-size: 12px;
}
